import { Container } from "react-bootstrap";
import Header from "../images/header-img.jpg";
import React from "react";
import Portfolio from "./layout/Portfolio";

export default function Home() {
  return (
    <div>
      <div className="container-max-width">
        {" "}
        <img src={Header} alt="" className="img-fluid item-shadows" />
      </div>
      <Container>
        <div>
          {" "}
          <h2 className="mt-2 mb-0">About</h2>
          <h3 className="mb-3">MyCapsule</h3>
        </div>
        <p className="mb-5">
          Is an innovative company in the emerging Corporate Wellness industry,
          specializing in providing holistic solutions for corporate
          development, performance, and employee well-being. Founded and led by
          Morten Linge, an advocate for health, lifestyle, and elite sports,
          MyCapsule is dedicated to making a lasting impact on people and
          enterprises worldwide.
        </p>
        <Portfolio />
      </Container>
    </div>
  );
}
