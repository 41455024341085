import React from "react";
import { Container } from "react-bootstrap";
import footer from "../../images/footer-img.png";

export default function Footer() {
  return (
    <div className="footer" style={{ backgroundImage: `url(${footer})` }}>
      <Container>
        <div className="d-flex justify-content-between py-4">
          <p className="link-button-color text-footer">2023 MyCapsule</p>
          <p className="link-button-color text-footer ">
            All Rights Reserved ©.
          </p>
        </div>
      </Container>
    </div>
  );
}
