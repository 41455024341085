import { Link } from "react-router-dom";
import home from "../../images/web.png";
import contact from "../../images/message.png";
import icon from "../../images/logo-transparency-2a.png";
import homeLogo from "../../images/home-logo.png";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

export default function Nav() {
  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isCloseToTop = currentScrollPos < 65;

      setHideNavbar(!isCloseToTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Function to handle link clicks and scroll to top
  const handleLinkClick = () => {
    // Set a short delay to allow any other click event handling to finish
    setTimeout(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, 0);
  };

  return (
    <div className="container-max-width">
      <div className={`navbar ${hideNavbar ? "hidden" : ""}`}>
        <nav>
          <Container>
            <ul className="navbar-list margin-s-2">
              <li>
                <Link className="icon-navbar-mobile" to="/">
                  <div className="navbar-item">
                    <img src={icon} alt="" className="logo padding-left" />
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="link-button-color"
                  to="/"
                  onClick={handleLinkClick}
                >
                  <div className="link-button-color navbar-item adjustments margin-s-6">
                    <img
                      src={homeLogo}
                      alt=""
                      className="white-image hide-on-desktop small-image-logo"
                    />
                    <span className="smaller-font-size">MyCapsule</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="link-button-color"
                  to="/About"
                  onClick={handleLinkClick}
                >
                  <div className="link-button-color navbar-item">
                    <img
                      src={home}
                      alt=""
                      className="white-image hide-on-desktop small-image"
                    />
                    <span className="smaller-font-size">Solutions</span>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  className="link-button-color"
                  to="/Contact"
                  onClick={handleLinkClick}
                >
                  <div className="link-button-color navbar-item">
                    <img
                      src={contact}
                      alt=""
                      className="white-image hide-on-desktop small-image"
                    />
                    <span className="smaller-font-size">Contact</span>
                  </div>
                </Link>
              </li>
            </ul>
          </Container>
        </nav>
      </div>
    </div>
  );
}
