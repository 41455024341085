import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import navService from "../../images/nav-img.png";
import peopleWalking from "../../images/header-about.jpeg";
import wellnessWalking from "../../images/wellness-walking.jpg";
import working from "../../images/working.jpg";

function AboutMe() {
  return (
    <div>
      <div
        className="nav-container"
        style={{ backgroundImage: `url(${navService})` }}
      >
        <img
          src={navService}
          alt=""
          className="img-fluid icon-navbar-item-mobile icon-navbar-mobile"
        />
      </div>

      <Container>
        {" "}
        <div className="mt-4">
          <img src={peopleWalking} alt="" className="img-fluid item-shadow  " />
          <h2 className="mt-2">Solutions</h2>
          <h3>MyCapsule Software Solution</h3>
          <div>
            <p>
              MyCapsule is actively developing a comprehensive
              Software-as-a-Service (SaaS) solution with the primary objective
              of empowering your company to establish, effectively manage, and
              continuously enhance their Corporate Wellness programs. We are
              dedicated to fostering a culture of innovation, aiming to equip
              each company with the tools and capabilities to shape the
              workplace of tomorrow. Through our cutting-edge platform,
              organizations can build their own digital ecosystem, tailored to
              their unique needs and objectives, while harnessing the power of
              technology to drive employee well-being and overall organizational
              success.
            </p>
          </div>

          <Row>
            <Col md={5} className="mt-4">
              <img
                src={wellnessWalking}
                alt=""
                className="img-fluid  item-shadow "
              />
            </Col>
            <Col md={5}>
              <div className="mt-4">
                <h3>Corporate Wellness Maturity Assessment</h3>
                <p>
                  The goal of this service is to assess a company's current
                  state of health promotion initiatives and identify areas for
                  growth and improvement - and address them.
                </p>
                <p>
                  It involves a review of the company's maturity in corporate
                  wellness, where we examine everything from current strategies
                  to conducting employee interviews, working together to create
                  and implement new strategies, and following up to ensure the
                  best possible outcome.
                </p>
              </div>
            </Col>{" "}
          </Row>

          <div className="mt-4">
            <h3 className="mt-4">Corporate Wellness & Strategy Consulting</h3>
            <p>
              At MyCapsule, we believe that high level of collaboration and a
              healthy and engaged workforce is the cornerstone of any successful
              organization. As an innovative company in the corporate wellness
              industry, we take pride in offering a comprehensive suite of
              services, including our specialized Corporate Wellness & Strategy
              Consulting.
            </p>
            <p>
              MyCapsule is dedicated to helping your company thrive by
              optimizing the health, happiness, and productivity of your company
              and your most valuable asset - your employees. With our
              cutting-edge solutions, we create a positive and sustainable
              impact on your organization's overall well-being and bottom line.
            </p>
            <img src={working} alt="" className="img-fluid item-shadow " />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AboutMe;
