import pictureOfMe from "../images/contact-me.png";
import { Container } from "react-bootstrap";
import nav from "../images/nav-img.png";

export default function SingleProduct() {
  return (
    <div>
      <div style={{ backgroundImage: `url(${nav})` }}>
        <img
          src={nav}
          alt=""
          className="img-fluid icon-navbar-item-mobile icon-navbar-mobile"
        />
      </div>
      <Container>
        <div className="mt-4">
          <div className="row">
            {" "}
            <div className="col-md-6">
              <img src={pictureOfMe} alt="" className=" img-fluid" />
            </div>
            <div className="col-md-6">
              <div>
                <h2>Contact</h2>
                <h3>Morten Eystberg Linge, CEO</h3>

                <p>
                  The founder of our company brings a wealth of experience and
                  expertise to our organization. As a Certified Corporate
                  Wellness Specialist with a bachelor's degree in Sports Science
                  from Norway and qualifications in E-commerce and marketing, he
                  is an invaluable asset.
                </p>
                <p>
                  Morten's extensive knowledge and proficiency have been honed
                  through his academic achievements, working in different
                  industries, and hands-on involvement in powerlifting, fitness,
                  and Strongman competitions. His dedication to his passions is
                  evident in his several championship victories in powerlifting,
                  establishing him as the Norwegian powerlifting champion.
                  Moreover, his performance led him to secure the title of
                  Norway's second strongest man in the national Strongman final
                  in 2022
                </p>
                <div className="mt-5">
                  <div>
                    {" "}
                    <a
                      className="highlight-contact button-pointer-color"
                      href="mailto:Morten@mycapsule.eu"
                    >
                      Morten@mycapsule.eu
                    </a>
                  </div>{" "}
                  <div className="mt-4">
                    <a
                      className="highlight-contact button-pointer-color"
                      href="tel:+47838491"
                    >
                      +47 478 38 491
                    </a>
                  </div>
                  <div className="mt-4 button-pointer-color">
                    {/* <a
                    className=" button-pointer-color"
                    href="https://www.google.com/maps/place/Regus+-+Stavanger,+2020+Forus,+Forusparken+2,+Ground+floor,+4031+Stavanger/@58.890748,5.7382606,17z/data=!4m6!3m5!1s0x463a35d6d5841b73:0x7dc29788af15f0d4!8m2!3d58.890748!4d5.7382606!16s%2Fg%2F11c2j1b_1b"
                  >
                    Office - Stavanger
                  </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
