// import PageNotFoundImage from "../images/avatar.png"; // this won't work for you if you haven't got an image

export default function NotFound() {
  return (
    <>
      <h1>Page not found</h1>
      {/* you can get rid of this image */}
      {/* <img
        src={PageNotFoundImage}
        alt="An error occurred, page was not found"
      /> */}
    </>
  );
}
