import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import mission from "../../images/our-mission.jpg";
import corporate from "../../images/corporate-wellness.jpg";
import whyMyCapsule from "../../images/why-myCapsule.jpg";

function Portfolio() {
  const [showMoreMission, setShowMoreMission] = useState(false);
  const [showMoreCorporate, setShowMoreCorporate] = useState(false);
  const [showMoreContact, setShowMoreContact] = useState(false);

  const toggleShowMore = (section) => {
    switch (section) {
      case "mission":
        setShowMoreMission((prevState) => !prevState);
        break;
      case "corporate":
        setShowMoreCorporate((prevState) => !prevState);
        break;
      case "contact":
        setShowMoreContact((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const missionText = `MyCapsule has recognized a significant gap within the conventional
  healthcare system, wherein enterprises and individuals who actively take proactive
  measures to manage their health and lifestyle often do so without the support of a
  dedicated infrastructure tailored to their unique needs. Presently, the healthcare
  system primarily revolves around disease management and reactive responses to health
  issues as they arise. In response to this void, MyCapsule is poised to enter the
  corporate wellness industry with an extensive portfolio of services that advocate and
  prioritize proactive healthcare.

  Our mission at MyCapsule is to empower organizations and their employees to reach
  their utmost potential by fostering a collaborative environment that enables exceptional
  achievements. MyCapsule believes that any company can create tomorrow's workplace,
  maximizing its collective achievements.`;

  const corporateText = `Corporate wellness refers to initiatives and programs implemented by organizations to promote and improve the health, well-being, and overall fitness of their employees. It recognizes that a healthy workforce leads to reduced absenteeism, increased productivity, reduced healthcare costs, and improved employee satisfaction.
The primary goal of corporate wellness is to create a supportive and healthy work environment that positively impacts both the employees and the organization as a whole.
Corporate wellness is not only beneficial to the employees' health and happiness but also contributes significantly to an organization's success, profitability, and long-term sustainability. By investing in their employees' well-being, companies create a positive and thriving work environment, ultimately leading to a competitive advantage in the market.`;

  const facts = `
- 25% reduction in absenteeism`;

  const facts2 = `
- Up to 550% ROI on Corporate Wellness investments`;

  const facts3 = `
- Happy employees are 12% more productive`;

  const contactText = `MyCapsule stands out in the corporate wellness industry with its unique and comprehensive Software-as-a-Service (SaaS) solution, designed to empower companies in establishing, managing, and continuously enhancing their corporate wellness programs. With a focus on innovation, MyCapsule aims to equip organizations with cutting-edge tools and capabilities, shaping the workplace of the future while driving employee well-being and overall organizational success. We are the first provider of multipurpose Corporate Wellness solutions that are adapted specifically for your company.`;

  return (
    <div>
      <Row>
        {/* Mission Section */}
        <Col xs={12} sm={6} md={4}>
          <div className="image-wrapper item-shadow mb-4">
            <div>
              <img src={mission} alt="" className="img-fluid image-item" />
            </div>
          </div>
          <div>
            <h3>Our Mission</h3>
            <p>
              {showMoreMission ? missionText : missionText.slice(0, 200)}
              <span
                style={{ display: showMoreMission ? "inline" : "none" }}
                onClick={() => toggleShowMore("mission")}
              >
                <button className="btn-read-more ">Read Less</button>
              </span>
              {!showMoreMission && (
                <span onClick={() => toggleShowMore("mission")}>
                  {" "}
                  <button className="btn-read-more ">Read More</button>
                </span>
              )}
            </p>
          </div>
        </Col>

        {/* Corporate Section */}
        <Col xs={12} sm={6} md={4}>
          <div className="image-wrapper item-shadow mb-4">
            <div>
              <img src={corporate} alt="" className="img-fluid image-item" />
            </div>
          </div>
          <div>
            <h3>Corporate Wellness</h3>
            <p>
              {showMoreCorporate ? corporateText : corporateText.slice(0, 200)}
              <span
                style={{ display: showMoreCorporate ? "inline" : "none" }}
                onClick={() => toggleShowMore("corporate")}
              >
                <p className="mt-2 highlight-text">{facts}</p>
                <p className="mt-2 highlight-text">{facts2}</p>
                <p className="mt-2 highlight-text">{facts3}</p>

                <button className="btn-read-more ">Read Less</button>
              </span>
              {!showMoreCorporate && (
                <span onClick={() => toggleShowMore("corporate")}>
                  {" "}
                  <button className="btn-read-more ">Read More</button>
                </span>
              )}
            </p>
          </div>
        </Col>

        {/* Contact Section */}
        <Col xs={12} sm={6} md={4}>
          <div className="image-wrapper item-shadow mb-4">
            <div>
              <img src={whyMyCapsule} alt="" className="img-fluid image-item" />
            </div>
          </div>
          <div>
            <h3>Why MyCapsule</h3>
            <p>
              {showMoreContact ? contactText : contactText.slice(0, 200)}
              <span
                style={{ display: showMoreContact ? "inline" : "none" }}
                onClick={() => toggleShowMore("contact")}
              >
                <h6 className="mt-4">Key Features and Services:</h6>
                <h3> Comprehensive SaaS Solution:</h3>
                <p>
                  MyCapsule's SaaS platform allows companies to build their own
                  digital ecosystem tailored to their specific needs and
                  objectives. This multipurpose solution is designed to address
                  various aspects of corporate wellness and provides a holistic
                  approach to employee well-being.
                </p>

                <h3 className="mt-4">
                  Corporate Wellness Maturity Assessment:
                </h3>
                <p>
                  MyCapsule offers a service that assesses a company's current
                  health promotion initiatives, identifying areas for growth and
                  improvement. This assessment includes a review of current
                  strategies, employee interviews, and collaboration to create
                  and implement new strategies for optimal outcomes.
                </p>

                <h3 className="mt-4">
                  Corporate Wellness & Strategy Consulting:
                </h3>
                <p>
                  MyCapsule believes in high levels of collaboration and
                  recognizes the value of a healthy and engaged workforce for
                  organizational success. Our specialized consulting services
                  focus on optimizing the health, happiness, and productivity of
                  employees, creating a positive and sustainable impact on
                  overall organizational well-being and performance.
                </p>

                <p>
                  MyCapsule sets itself apart with a unique workflow that
                  enables the development of individualized solutions for each
                  client. By understanding a company's specific needs and
                  challenges, MyCapsule establishes a defined Maturity Level,
                  action plan, and software solution that fosters long-term
                  health for the organization and its people. This personalized
                  approach maximizes achievements and ensures that companies and
                  their employees thrive together.
                </p>

                <p>
                  In conclusion, MyCapsule's commitment to innovation,
                  comprehensive SaaS solution, corporate wellness maturity
                  assessment, and specialized consulting services make it a
                  standout choice in the corporate wellness industry. Our
                  emphasis on individualized solutions and long-term health
                  promotion sets us apart from the competition, making MyCapsule
                  the preferred partner for companies seeking to create a
                  healthy, engaged, and successful workforce.
                </p>

                <button className="btn-read-more">Read Less</button>
              </span>
              {!showMoreContact && (
                <span onClick={() => toggleShowMore("contact")}>
                  {" "}
                  <button className="btn-read-more">Read More</button>
                </span>
              )}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Portfolio;
