import Nav from "./Nav";
// import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
// import logo from "../../images/company-logo-2.jpg";

export default function Header() {
  return (
    <>
      <Container>
        <div></div>
      </Container>
      <Nav />
    </>
  );
}

// <Link to="/">s
//   <h1>
//     <img src={logo} alt="" className="logo" />
//   </h1>
// </Link>
